<template>
  <vx-card style="padding-left: 10px" :key="rerenderKey">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="aggregatedReportData"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between"
        >
          <div style="float: left">
            <vs-select
              placeholder="10"
              autocomplete
              vs-multiple
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="doctorName">Doctor</vs-th>
          <vs-th sort-key="sendDate">Sent On</vs-th>
          <vs-th sort-key="year">Year</vs-th>
          <vs-th sort-key="month">Month</vs-th>
          <!-- <vs-th sort-key="startDate">Start Date</vs-th>
          <vs-th sort-key="endDate">End Date</vs-th>-->
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th sort-key="paidStatus">Payment Status</vs-th>
          <vs-th size="25%">Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].doctorName">
              {{ data[indextr].doctorName | capitalize }}
            </vs-td>
            <vs-td :data="data[indextr].sendDate">
              {{ data[indextr].sendDate | date_formatter }}
            </vs-td>
            <vs-td :data="data[indextr].year">{{ data[indextr].year }}</vs-td>
            <vs-td :data="data[indextr].month">{{ data[indextr].month }}</vs-td>
            <!-- <vs-td :data="data[indextr].startDate">{{ data[indextr].startDate | date_formatter}}</vs-td>
            <vs-td :data="data[indextr].endDate">{{ data[indextr].endDate | date_formatter}}</vs-td>-->
            <vs-td
              :data="data[indextr].createdAt"
              style="white-space: nowrap;"
              >{{ data[indextr].createdAt | date_formatter }}</vs-td
            >
            <vs-td :data="data[indextr].paidStatus">
              <p
                v-if="data[indextr].paidStatus === 'Pending'"
                @click="payToDoctor(data[indextr]._id)"
              >
                <span style="display:block;">{{
                  data[indextr].paidStatus
                }}</span>
                <vs-chip color="danger" style="cursor: pointer;">Retry</vs-chip>
              </p>

              <p
                v-if="
                  data[indextr].paidStatus === 'Hold' ||
                    data[indextr].paidStatus === 'Failed'
                "
              >
                <!-- <vx-tooltip
                  v-if="data[indextr].reason !== 'Payment pendings from associated clinics'"
                  :text="data[indextr].reason"
                  position="left"
                >
                  {{ data[indextr].paidStatus }}
                  <feather-icon
                    style="margin-right: 5px; color: red;"
                    icon="InfoIcon"
                    svgClasses="h-4 w-4"
                  ></feather-icon>
                </vx-tooltip>-->
                <span style="display: block;">{{
                  data[indextr].paidStatus
                }}</span>
              </p>

              <p v-else-if="data[indextr].paidStatus === 'Succeeded'">
                {{ data[indextr].paidStatus }}
              </p>

              <p v-else>{{ data[indextr].paidStatus }}</p>
            </vs-td>

            <vs-td :data="data[indextr]._id">
              <div
                style="display: flex;justify-content: left;align-items: center;"
              >
                <div
                  v-if="
                    data[indextr].paidStatus === 'Hold' ||
                      data[indextr].paidStatus === 'Failed'
                  "
                >
                  <vx-tooltip text="View Details" position="top">
                    <vs-button
                      color="#3dc9b3"
                      type="border"
                      @click="viewDetails(data[indextr]._id)"
                      style="padding: 4px 10px;"
                      >View Details</vs-button
                    >
                  </vx-tooltip>
                </div>

                <div>
                  <vx-tooltip text="View Report" position="top">
                    <a
                      style="float: left;"
                      :href="data[indextr].pdfLink"
                      target="_blank"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        svgClasses="h-4 w-4"
                        class="vs-component vs-button m-1 vs-button-primary vs-button-border includeIcon includeIconOnly small"
                        style="background: transparent; float: left;"
                        type="border"
                        size="small"
                      ></feather-icon>
                    </a>
                  </vx-tooltip>
                </div>
              </div>
              <!-- <vx-tooltip text="Download Report">
                <a style="float: left;" :href="data[indextr].pdfLink" target="_blank" download>
                  <feather-icon
                    icon="DownloadIcon"
                    svgClasses="h-4 w-4"
                    class="vs-component vs-button m-1 vs-button-primary vs-button-border includeIcon includeIconOnly small"
                    style="background: transparent; float: left;"
                    type="border"
                    size="small"
                  ></feather-icon>
                </a>
              </vx-tooltip>-->
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      noDataText: "",
      rerenderKey: 0,
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        activeClinicId: ""
      },
      serverResponded: false,
      aggregatedReportData: [],
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      awaitingSearch: null
    };
  },
  methods: {
    ...mapActions("general", [
      "fetchAdminAggregatedReport",
      "payInvoiceAmountToDoctor",
      "deleteDoctorInvoiceDataFromDb"
    ]),
    async getReportList() {
      let self = this;
      this.$vs.loading();
      await this.fetchAdminAggregatedReport(self.dataTableParams)
        .then(res => {
          this.$vs.loading.close();
          self.aggregatedReportData = res.data.data.docs;
          self.totalDocs = res.data.data.pagination.total;
          self.page = res.data.data.pagination.page;
          self.serverResponded = true;
          self.noDataText = "No any report available";
        })
        .catch(err => {
          this.$vs.loading.close();
          console.log("This is the error ::", err);
        });
    },
    payToDoctor(invoiceId) {
      let self = this;
      self.$vs.loading();
      this.payInvoiceAmountToDoctor(invoiceId)
        .then(res => {
          self.$vs.loading.close();
          self.$vs.notify({
            title: "Payout Successfully Initiated",
            text: "Success",
            color: "success"
          });
          this.forceRerender();
        })
        .catch(err => {
          self.$vs.loading.close();
          self.$vs.notify({
            title: "Payment Failed",
            text: err.data.message,
            color: "danger"
          });
        });
    },
    viewDetails(invoiceId) {
      const loggedInUserType =
        this.$store.state.AppActiveUser.userType === "superAdmin"
          ? "super-admin"
          : this.$store.state.AppActiveUser.userType;

      this.$router.push(
        `/${loggedInUserType}/doctor-aggregated-report/${invoiceId}/details`
      );
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getReportList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getReportList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getReportList();
    },
    forceRerender() {
      this.rerenderKey += 1;
    },
    deleteDoctorInvoiceData() {
      this.$vs.loading();
      this.deleteDoctorInvoiceDataFromDb()
        .then(result => {
          this.$vs.notify({
            title: "Success",
            text: "Successfully flushed doctor invoice table",
            color: "success"
          });
          this.$vs.loading.close();
        })
        .catch(err => {
          console.log(err);
          this.$vs.notify({
            title: "Error",
            text: err.data.message,
            color: "danger"
          });
          this.$vs.loading.close();
        })
        .finally(() => {
          this.forceRerender();
        });
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getReportList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getReportList();
      }
    },
    rerenderKey: function(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getReportList();
      }
    },
    "$store.state.AppActiveClinicId": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.activeClinicId = newVal;
        this.getReportList();
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  async created() {
    this.dataTableParams.activeClinicId = await this.$store.state
      .AppActiveClinicId;
    this.getReportList();
  }
};
</script>

<style scoped></style>
